#root {
  border-top: 10px solid red;
  position: fixed;
  top: 0;
  bottom: 0;
}

.App {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#statewatch {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
  color: #999;
}

.toolbar {
  background-color: #222;
  color: #bbb;
  height: 82px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
}

.toolbar button {
  min-height: 30px;
  background: none;
  border: none;
  color: white;
}

.toolbar button.selected {
  background: #999;
}

.react-draggable {
  cursor: default;
  padding: 1px;
}

.mainArea {
  position: relative;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.precedenceChart {
  flex: 1;
}

.chartCanvas,
.precedenceChart .react-transform-component {
  width: 100%;
  height: 100%;
  position: relative;
}
.precedenceChart .chartCanvas > .arrow {
  position: absolute;
}

.chartNode {
  text-align: center;

  /* Position an invisible box around the node absolutely within the chart,
     using the centerpoint of its top edge as reference */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 0;
}


[contenteditable] {
  min-height: 20px;

  /* Safari has this set to 'none' by default */
  -webkit-user-select: text;
  user-select: text;

  /* Safari changes text color to white when :active by default; keep it black all the time */
  color: black;
}

.selected [contenteditable="true"]:focus {
  background: white;
}

.relation,
.relationButtonIcon {
  text-align: center;
  min-width: 50px;
  max-width: 200px;
  display: inline-block;
  padding: 8px 10px;
  border: 2px solid #999;
  background: #eee;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.relationEdit.emptyName {
  border: 1px dashed #999;
  background: white;
}

.keyboardSelectButton {
  position: absolute;
  height: 10px;
  width: 10px;
  background: none;
  border: none;
}

.selected .relation {
  border-color: black;
  background-color: #ffe200;
}

.relationButtonIcon {
  font-size: 10px;
  color: black;
}

.centeredOnZeroWidthParent {
  margin-left: -300px;
  margin-right: -300px;
}

.operator {
  position: relative; /* allow SVG to overlap exactly relative to this */
  padding-bottom: 1px;
}

.operatorSvg {
  fill: #d8e0f8;
  stroke: black;
  stroke-width: 1;
}
.selected .operatorSvg {
  fill: #7d9eff;
}


.underlay {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
}

.operatorContent {
  display: inline-block;
  padding: 10px;
}

.bottomFix {
  margin-bottom: -2px; /* Counteract effective bottom margin caused by inline-block */
}

.operatorName {
  text-align: center;
  padding-bottom: 5px;
}

.operatorParams {
  text-align: left;
  display: inline-block;
  min-width: 50px;
  max-width: 400px;
  vertical-align: text-top;
  font-family: sans-serif;
  margin: 5px;
  margin-top: 0px;
}

.operator.Pill .operatorParams {
  min-width: 70px;
  text-align: center;
}

.operator.Hexagon .operatorParams {
  margin: 0 20px;
}

.missingInput {
  background: #faa;
  opacity: 0.7;
  border: none;
  position: absolute;
  font-size: 14px;
  width: 20px;
  height: 20px;
  margin-top: -20px;
}

.App.connect .missingInput:focus {
  background: #f33;
}

.arrowLine {
  stroke: black;
  stroke-width: 1;
}

.arrow.selected .arrowLine {
  stroke: blue;
  stroke-width: 5;
}


.arrowClickArea {
  stroke: transparent;
  stroke-width: 25;
}

.arrowHead {
  stroke: none;
  fill: black;
}

.arrow.selected .arrowHead {
  stroke: none;
  fill: blue;
}

.operatorButton {
  position: relative;
  text-align: center;
  margin: 4px 0;
  height: 75px;
  vertical-align: bottom;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.operatorButton .iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-basis: 65%;
}

.operatorButton label {
  display: block;
  font-size: 12px;
  margin-bottom: auto;
  flex-basis: 35%;
}
